import { ComponentProps } from "solid-js";
import c from "class-c";

import styles from "./Line.module.scss";

declare namespace Line {
  type Props = ComponentProps<"div">;
}

function Line(props: Line.Props) {
  return <div {...props} class={c`${styles["line"]} ${props.class}`} />;
}

export default Line;
